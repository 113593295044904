'use client';
import React from 'react';
import dynamic from 'next/dynamic';

import { styled } from '@mui/material/styles';

import ContentModule from '../ContentModule';
const BackToTop = dynamic(() => import('../BackToTop'));
import sidekick from '@last-rev/contentful-sidekick-util';

import type { PageProps, PageOwnerState } from './Page.types';
import { useJsonLd } from '../JsonLd';

const Page = (props: PageProps) => {
  const { addJsonLdItem } = useJsonLd();
  const {
    id,
    header,
    hero,
    subNavigation,
    contents,
    footer,
    disableBackToTop,
    sidekickLookup,
    jsonLd,
    footerDisclaimerOverride,
    searchParams
  } = props;

  const ownerState = {
    ...props
  };

  React.useEffect(() => {
    if (jsonLd) addJsonLdItem(jsonLd);
  }, [jsonLd]);

  return (
    <>
      {header ? <ContentModule key={header.id} {...(header as any)} /> : null}

      {hero ? <ContentModule key={hero?.id} {...(hero as any)} /> : null}

      <Main {...sidekick(sidekickLookup, 'contents')} ownerState={ownerState}>
        {subNavigation ? (
          <ContentModule key={subNavigation?.id} {...(subNavigation as any)} />
        ) : null}
        {contents?.map((content: any) => (
          <ContentModule
            key={content?.id}
            {...content}
            component="section"
            searchParams={searchParams}
          />
        ))}
        {!disableBackToTop ? <BackToTop /> : null}
      </Main>

      {footer ? (
        <ContentModule
          key={footer.id}
          {...(footer as any)}
          disclaimerText={footerDisclaimerOverride ?? footer.disclaimerText}
        />
      ) : null}
    </>
  );
};

const Main = styled('main', {
  name: 'Page',
  slot: 'Main',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: PageOwnerState }>``;

export default Page;
